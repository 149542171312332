@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tenor+Sans&display=swap');
/* General */

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background: #000;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #ddd;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #927766;
  }
body{
    font-family: 'Montserrat', sans-serif !important;
    scroll-behavior: smooth;
    top:0 !important;
}


.pointerCursor{
  cursor: pointer;
}


h1,h2,h3,h4,h5,h6{
    font-family: 'Tenor Sans', sans-serif;
}
.text-main{
    color: #927766 !important;
}
.text-sec{
    color: #C2B4AB !important;
}
.btn-main {
  background-color:  #927766 !important;
  color: #fff !important;
  text-transform: uppercase !important;
  border-radius: 0px !important;
  padding: 10px 25px !important;
}
.projDet {
  background-color: rgb(255 255 255 / 50%);
  padding: 10px;
}
.btn-sec {
  background-color:  #C2B4AB !important;
  color: #fff !important;
  text-transform: uppercase !important;
  border-radius: 0px !important;
  padding: 10px 25px !important;
  width: 100%;
}
.btn-dark{
  background-color:  #333 !important;
  color: #fff !important;
  text-transform: uppercase !important;
  border-radius: 0px !important;
  padding: 10px 25px !important;
}
.borderMain {
  border: 1px solid #927766 !important;
}
.navbar-collapse.collapse.show{
background-color: #000000ab;
}
.bgMain{
    background-color: #927766 !important;
}
.bgLinear {
    background: linear-gradient(180deg, #927766 85%, #fff 25%);
}
.fs-20{
    font-size: 20px;
}
.fs-12{
    font-size: 12px;
}
.fs-14{
    font-size: 14px;
}
/* General */

.bg-navBg {
    background-color: rgb(0, 0, 0, 0.8 );
}
.navFixed {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    height:100px;
    transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}
.navFixed.Hide {
    top: -120px!important;
}
.navLink .nav-link{
    color: #fff !important;
    font-size: 13px;
    text-transform: uppercase;
    margin: 0px 5px;
}
.navLink .nav-link.active{
  border-bottom: 2px solid #694E42;
}
.footNavLink .nav-link{
    color: #C2B4AB !important;
    font-size: 13px;
    text-transform: uppercase;
}
.social-sticky {
  position: fixed;
    z-index: 5;
    right: 2em;
    bottom: 1em;
}
.social-sticky ul{
  list-style: none;
}
.social-sticky ul li{
  margin-bottom: 10px;
}
.social-sticky ul li img{
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  border-radius: 50%;
}
.dflexCenter {
  display: flex;
  justify-content: center;
}
.bgGrey {
    background-color: #eee;
}
.line-before-after {
    overflow: hidden;
    text-align: center;
  }
  
  .line-before-after:before,
  .line-before-after:after {
    background-color: #927766;
    content: "";
    display: inline-block;
    height: 8px;
    position: relative;
    vertical-align: middle;
    width: 10%;
  }
  
  .line-before-after:before {
    right: 0.5em;
    margin-left: -50%;
  }
  
  .line-before-after:after {
    left: 0.5em;
    margin-right: -50%;
  }

  .foot-blog .card-title h5{
    font-size: 14px;
  }
  .foot-blog .card-text small{
    color: #C2B4AB;
  }
  .footForm .form-control {
    border: 1px solid #927766 !important;
    border-radius: 0px !important;
    resize: none;
  }
  .payForm .form-control, .payForm .form-select {
    border: 1px solid #927766 !important;
    border-radius: 0px !important;
    resize: none;
  }
  .payForm .form-control::placeholder, .payForm .form-select::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 13px;
  }
  
  .payForm .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: 13px;
  }
  
  .payForm .form-control::-ms-input-placeholder { /* Microsoft Edge */
    font-size: 13px;
  }
  .footForm .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 13px;
  }
  
  .footForm .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: 13px;
  }
  
  .footForm .form-control::-ms-input-placeholder { /* Microsoft Edge */
    font-size: 13px;
  }
  .btnMain {
    background-color: #333 !important;
    border: 0px !important;
    border-radius: 0px !important;
    width: 100%;
    text-transform: uppercase;
    font-weight: 500 !important;
  }
  .footSocLogo a{
    text-decoration: none !important;
    font-size: 24px;
    color: #C2B4AB !important;
    padding-right: 20px;
  }
  .linkFoot {
    color: #C2B4AB;
  }
  .linkFoot .nav-link {
    font-size: 16px;
    color: #C2B4AB;
    font-weight: 500;
  }
  .main-bg-img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background: url(../Images/banner/homeBg.webp);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .videoMain{
    height: 100vh;
    object-fit: cover;
  }
  .videoOverlay{
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    right: 0;
    bottom: 0;
  }
  .main-heading{
    font-size: 3.5rem !important;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
  }
  .mainPara {
    color:#fff;
    text-align: center;
  }
  .bannerCarousel .carousel-indicators, .bannerCarousel .carousel-control-next, .bannerCarousel .carousel-control-prev {
    display: none !important;
  }
  .bannerCarousel .carousel-item img{
    height: 100vh;
    object-fit: cover;
  }
  .bannerCarousel .footSocLogo img{
    width: 100%;
    height: 60px;
    object-fit: contain;
  }
  .newsDetails.bannerCarousel .carousel-item img{
    position: relative;
  }
  .newsDetails.bannerCarousel .carousel-item .bgOverlay{
   position: absolute;
   top:0;
   left: 0;
    background-color: rgba(0, 0, 0, 0.615);
    width: 100%;
    height: 100vh;
    
  }
  .bannerCarousel .carousel-item{
    min-height: 100vh;
    height: 100%;
  }
  .bannerCarousel .container {
    z-index: 1;
  
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
}
.scrollDiv {
    font-size: 12px;
    text-transform: uppercase;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    position: relative;
}
.bannerCarousel .carousel-caption {
z-index: 99;
left:50% !important;
width: fit-content;
}
.line {
  border-right: 1px solid #fff;
  height: 60px;
  display: block;
  text-align: center;
  margin: 5px 10px;
}
.scrollDiv .arrowSpan{
  position:absolute;
  bottom:-15px;
  left:6px;
}
.scrollDiv .arrowSpan div{
  display: block;
  width: 8px;
  height: 8px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
       transform: rotate(45deg);
       transform: rotate(45deg);
  border-right: 2px solid #fff; 
  border-bottom: 2px solid #fff;
}
.scrollDiv .down-arrow-1 {
  margin-top: 10px !important;
  margin-left: -8px !important;
}
.scrollDiv .down-arrow-1, .scrollDiv .down-arrow-2 {
  -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
.mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
.mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}

@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.aboutSec {
    position:relative;
}
.aboutSec .containerbackground h1{
    font-size:25rem;
}
.aboutSec .containerbackground {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    color: #efefef87;
}
.bgAbout {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background: url(../Images/bgAboutHome.png);
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.imageHoverCont {
    background-color: #927766;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100px;
    width: 100px;
}
.imgMainHover img{
    width:100px;
    height: 100px;
}
div#wave {
	position:relative;
	text-align:center;
	margin-left: auto;
	margin-right: auto;
	
}
.dot {
    display:inline-block;
    width:12px;
    height:12px;
    border-radius:50%;
    margin-right:3px;
    background:#fff;
    animation: wave 1.3s linear infinite;

    
}
.dot:nth-child(2) {
    animation-delay: -1.1s;
}

.dot:nth-child(3) {
    animation-delay: -0.9s;
}
@keyframes wave {
	0% {
		transform: initial;
	}
	60% {
		transform: initial;
	}
	100% {
		transform: initial;
        display: none !important;
	}

	30% {
		transform: translateY(-15px);
	}
}
.imageHoverCont .imgHoverAfter{
    display: none;
}
/* .imgMainHover:hover {
    display: none;
    transition-timing-function: ease-out;
}
.imgMainHover:hover .imgHoverAfter{
    display: flex;
    transition-timing-function: ease-in;
} */
.rounded50{
  border-radius: 40px;
}
.imgContPro {
    position: relative;
}
.proDetails{
    position: absolute;
    background-color: rgb(0, 0, 0,0.4);
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    z-index: 9;
}
.proDesc {
    display: none;
}
.imgContPro:hover .proDetails{
    background-color: rgb(0, 0, 0,0.7);
}
.imgContPro:hover .proDesc{
    display: block;
    transition-timing-function: ease-in;
}
.mapImagCont {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background: url(../Images/bgAboutHome.png);
    height: auto !important;
    min-height: 400px;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
}
.mapContComm {
  position: relative;
}
.mapContComm img{
  position: relative;
}
.lg-hotspot {
    position: absolute;
    margin: 0;
    padding: 0;
    transform: translate(-50%, -50%);
    z-index: 0;
    cursor: pointer;
  }
.lg-hotspot.lg-hotspot--top-left {
    bottom: 16%;
    right: 25%;
  }
.lg-hotspot.lg-hotspot--top-right {
  bottom: 15%;
  right: 15%;
  }
.lg-hotspot.lg-hotspot--top-center {
    top: 50%;
    left: 40%;
  }
  .lg-hotspot__label img{
    width: 100%;
    height: 170px;
    object-fit: cover;
  }
.lg-hotspot.lg-hotspot--bottom-center {
    top: 40%;
    left: 45%;
  }
 
  @keyframes ripple {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0.5;
   }
   100% {
       transform: translate(-50%, -50%) scale(2);
       opacity: 0;
  }
}
@keyframes hover {
    0% {
        margin-top: 0px;
   }
   50% {
       margin-top: 10px;
  }
}
  .lg-hotspot__button {
    font-size: 30px;
    padding: 0px;
    z-index: 9;
    color: red;
    animation:hover 1s infinite ease-in;
  }
  .pulse {
    position: absolute;
    top: -10%;
    left: 50%;
    display: block;
    width: 30px;
    height: 15px;
    background: rgb(0, 0, 0,0.5);
    /* background alternative white */
    border-radius: 50%;
    transform: translate(-50%, 400%);
}
.pulse:before, .pulse:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0,0.5);
    border-radius: 50%;
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(1);
    animation: ripple 1s infinite ease-in;
}
.lg-hotspot__label#Active{
  display: block;
  opacity: 1;
  z-index: 999;
}
  .lg-hotspot__label {
    position: absolute;
    display: none;
    width: 16em;
    max-width: 50vw;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: -1;
    border-radius: 2px;
    opacity: 0;
    transition: all 0.1s linear;
  }
 
  .lg-hotspot--top-left .lg-hotspot__label {
    top: 24px;
    right:45px;
  }
  
  .lg-hotspot--top-right .lg-hotspot__label {
    top: 60px;
    right: 50px;
  }
  
  .lg-hotspot--top-center .lg-hotspot__label {
    right: 45px;
    top: 24px;
  }
  .lg-hotspot--bottom-center .lg-hotspot__label {
    right: 45px;
    top: 24px;
  }
  .pswp__counter{
    opacity: 0 !important;
    display: none !important;
  }
  
  @keyframes button-pulse {
    0% {
      transform: scale(1, 1);
      opacity: 1;
    }
    40% {
      transform: scale(1.15, 1.15);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 1;
    }
  }
 .mySwiperWhy .swiper-horizontal>.swiper-pagination-bullets, .mySwiperWhy .swiper-pagination-bullets.swiper-pagination-horizontal, .mySwiperWhy .swiper-pagination-custom, .mySwiperWhy .swiper-pagination-fraction {
    bottom: 20px !important;
    left: auto !important; 
    right: 38%;
    width: fit-content !important;
    font-family: 'Tenor Sans', sans-serif;
}
.mySwiperWhy .swiper-button-next {
    top: auto !important;
    bottom: 10px !important;
    font-size: 20px !important;
    right: 34% !important;
}
.mySwiperWhy .swiper-button-prev {
    top: auto !important;
    bottom: 10px !important;
    
    left: 55% !important;
}
.mySwiperWhy .swiper-button-next:after, .mySwiperWhy .swiper-button-prev:after{
    font-size: 20px !important;
    color: #927766 !important;

}
.myTestiSwiper .swiper-wrapper {
    height:600px;
}
.testiCont {
    background-color: #efefef87;
}
.myTestiSwiper .swiper-slide-active .testiCont{
    background-color: #694E42;
    color: #fff !important;
    position: absolute;
    left: -45px;
    overflow: visible;
}
.counterMain h1{
    font-size: 4rem;
}
.accordBenefit .accordion-item {
    border: 0px !important;
    border-bottom: 1px solid #927766 !important;
}
.accordBenefit .accordion-button:not(.collapsed) {
    color: #927766 !important;
    background-color: transparent !important;
    box-shadow:none !important;
}
.accordBenefit .accordion-button:focus {
border-color: transparent !important;
box-shadow: none !important;
}
.form-control:focus, .form-check:focus {
border-color: transparent !important;
box-shadow: none !important;
}
.form-select:focus {
  box-shadow: none !important;
}
.accordBenefit .accordion-button {
    color: #927766 !important;
    font-size: 20px !important;
}
.accordBenefit .accordion-item:first-of-type, .accordBenefit .accordion-item:last-of-type, .accordBenefit .accordion-item:first-of-type .accordion-button {
    border-radius: 0px !important;
}
.accordBenefit .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}
.accordBenefit .accordion-button::after{
    background-image: url(../Images/arrow.png) !important;
}
.accordBenefit .accordion-item:last-of-type{
    border-bottom: 0px !important;
}
.testiDesc p {
    font-size: 16px;
    font-family: 'Tenor Sans', sans-serif;
}
/* news */

.newsSwiper .newsSwipGrid1{
  position: relative;
  width: 100% !important;
  height: 600px !important;
  object-fit: cover !important;
}
.newsSwiper .newsSwipGrid2{
  position: relative;
  width: 100% !important;
  height: 290px !important;
  object-fit: cover !important;
}
.newsSwiper .newsOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(146,119,102,1) 70%, rgba(146,119,102,1) 100%);
  opacity: 0.9;
}
.newsSwiper .blogDetCont {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  z-index: 99;
}
.newsSwiper .swiper-button-next, .newsSwiper .swiper-button-prev{
  background-color: #927766 !important;
  width: 40px !important;
  color: #C2B4AB !important;
}
.newsSwiper .swiper-button-next:after, .newsSwiper .swiper-button-prev:after{
  font-size: 22px !important;
}
.newsSwiper .swiper-button-next.swiper-button-disabled, .newsSwiper .swiper-button-prev.swiper-button-disabled{
  background-color: #000 !important;
  opacity: 0.6 !important;
}
.p-relative{
  position: relative;
}
.borNewBott {
  border-bottom: 2px solid #eee;
  position: relative;
}
.borNewBott::after {
    content: '';
    border-bottom: 2px solid #eee;
    position: absolute;
    left: 0;
    width: 75px;
    height: 5px;
    background-color: #927766;
}
/* news */
/* career */
.boxPrimary{
  position: absolute;
  background-color: #927766;
  width: 250px;
  height: 250px;
  z-index: -1;
  right: 0;
  top: 0;
}
/* career */
/* career */
.listPromise li {
font-style: italic;
font-weight: 500;
font-size: 18px;
margin-bottom: 1rem;
}
.leaderTest p {
  font-size: 30px;
  font-weight: 400;
}
.leaderName p {
  font-size: 23px;
}
.leaderCont {
  padding-right: 30px;
}
.bgGreyGrad {
  background: linear-gradient(180deg, #fff 20%, #eee 20%);
}
/* career */
/* career */
.lineJourney {
  background-color: #694E42;
  height: 80px;
  width: 2px;
  position: absolute;
  left: 100px;
  bottom: -40px;
}
.lineJourneyNew {
  background-color: #694E42;
    height: 80px;
    width: 2px;
    position: absolute;
    left: 100px;
    top: -40px;
}
.paddJourn{
  padding: 60px 0px;
}
.borderlight {
  border-bottom: 10px solid #694e4221;
}
.btn-mainLgOut {
  border: 1px solid #694E42 !important;
  text-transform: uppercase !important;
  background-color: transparent !important;
  color: #694E42 !important;
  border-radius: 0px !important;
  padding: 12px 30px !important;
}
.btn-whiteLgOut {
  border: 1px solid #fff !important;
  text-transform: uppercase !important;
  background-color: transparent !important;
  color: #fff !important;
  border-radius: 0px !important;
  padding: 12px 30px !important;
}
.bg-lightDark{
  background-color: #333;
}
.locatBgGrad {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image:linear-gradient(to bottom, transparent 60%, #fff 60%), url(../Images/property/residence/LRbg2.webp);
  width:100%;
  display:table;
}
.locatBgGradLeft {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image:linear-gradient(to left, transparent 10%, #C2B4AB 50%), url(../Images/property/heights/LHbg.webp);
  width:100%;
  display:table;
}
.bgDarkLinear {
    background: linear-gradient(90deg,#fff 50%, #333 50%);
}
.swipGallery .swiper-wrapper .swiper-slide{
  transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(0deg) scale(0.9) !important;
}
.swipGallery .swiper-wrapper .swiper-slide img{
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.swipGallery .swiper-wrapper .swiper-slide-active{
  transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(0deg) scale(1.2) !important;
}
.custom-pagination {
  color: #fff;
  font-size: 16px;
  width: fit-content !important;
}
.buttonGall {
  background-color: transparent !important;
  border: 1px solid #fff !important;
  color:  #fff !important;
  font-size: 22px !important;
  padding: 5px 10px !important;
}
.lineHr {
  height: 1px;
  width: 50px;
  flex: 1;
  background-color: #fff;
}
/* career */
@media screen and (max-width: 765px) {
  .lg-hotspot.lg-hotspot--top-left{
    bottom: 8%;
    right: 10%;
  }
  .lg-hotspot.lg-hotspot--top-right {
    bottom: 10%;
    right: 20%;
}
  .lg-hotspot.lg-hotspot--bottom-center {
    top: 40%;
    left: 59%;
}
.lg-hotspot--top-center .lg-hotspot__label {
  right: -15px;
  top: 80px;
}
  .main-heading{
    font-size: 2.5rem !important;
  }
  .dflexCenter{
    display: block !important;
  }
  .line-before-after{
    font-size: 12px !important;
  }
  .bgAbout{
    background-position: left !important;
  }
  .mySwiperWhy .swiper-horizontal>.swiper-pagination-bullets, .mySwiperWhy .swiper-pagination-bullets.swiper-pagination-horizontal, .mySwiperWhy .swiper-pagination-custom, .mySwiperWhy .swiper-pagination-fraction{
    right:50% !important
  }
  .mySwiperWhy .swiper-button-prev{
    left:25% !important;
  }
  .mySwiperWhy .swiper-button-next:after, .mySwiperWhy .swiper-button-prev:after {
    color: #fff !important;
  }
  .mySwiperWhy .swiper-wrapper .swiper-slide{
    bottom:20px !important;
  }
  .bgLinear{
    background: linear-gradient(180deg, #927766 100%, #fff 100%) !important;
  }
  .lg-hotspot__label {
    width: 10rem !important;
  }
  .lg-hotspot--top-left .lg-hotspot__label {
    top: 75px;
    right: 30px;
}
.lg-hotspot--top-right .lg-hotspot__label {
  top: 50px !important;
  right: 28px !important;
}
.aboutSec .containerbackground h1 {
  font-size: 8rem !important;
}
.lg-hotspot__label img{
  height: 100px !important;
}
.bgGreyGrad{
  background: linear-gradient(180deg, #fff 80%, #eee 80%);
}
.bgDarkLinear {
  background: linear-gradient(90deg,#fff 10%, #333 10%);
}
.rowRev {
  flex-direction: column-reverse;  
}
.lineJourney {
  background-color: #694E42;
  height: 0px;
  width: 2px;
  position: absolute;
  left: 100px;
  bottom: -40px;
}
.lineJourneyNew {
  background-color: #694E42;
    height: 0px;
    width: 2px;
    position: absolute;
    left: 100px;
    top: -40px;
}
}
@media screen and (max-width: 1024px) and (min-width: 766px) {
  .aboutSec .containerbackground h1{
    font-size: 10rem;
  }
  .lg-hotspot--top-right .lg-hotspot__label {
      top: -280px;
      left: -90px;
  }
  .bgLinear {
      background: linear-gradient(180deg, #927766 100%, #fff 100%);
  }
  .mySwiperWhy .swiper-button-next:after, .mySwiperWhy .swiper-button-prev:after{
    color:#fff !important;
  }
  .mySwiperWhy .swiper-button-prev{
    left:53% !important;
  }
  .mySwiperWhy .swiper-button-next, .mySwiperWhy .swiper-button-prev{
    bottom: -10px !important;
  }
  .mySwiperWhy .swiper-horizontal>.swiper-pagination-bullets, .mySwiperWhy .swiper-pagination-bullets.swiper-pagination-horizontal, .mySwiperWhy .swiper-pagination-custom, .mySwiperWhy .swiper-pagination-fraction{
    bottom: 0px !important;
  }
}
.fontSize-30{
  font-size: 30px;
}
@media screen and (max-width: 1344px) and (min-width: 990px){
  .main-heading{
    font-size: 2.2rem !important;
  }
  .navLink .nav-link{
    font-size: 11px !important;
  }
  .heading-all h2{
    font-size: 1.4rem;
  }
  .heading-all h1{
    font-size: 1.6rem;
  }
  .heading-all h4{
    font-size: 1.2rem;
  }
  .text-para p{
    font-size: 13px !important;
  }
  p{
    font-size: 13px !important;
  }
  .proName h4{
    font-size: 1.1rem;
  }
  .mapContComm .mapIMg{
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .lg-hotspot__label img{
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  .lg-hotspot__button{
    font-size: 27px !important;
  }
  .counterMain h1 {
      font-size: 3rem;
  }
  .accordBenefit .accordion-body{
    font-size: 13px;
  }
  .accordBenefit .accordion-button {
      font-size: 16px !important;
  }
  .line-before-after {
    font-size: 16px;
  }
  a{
    font-size: 14px;
  }
  .footIcon{
    font-size: 14px;
  }
  .footNavLink .nav-link{
    font-size: 12px;
  }
  .footHead h4{
    font-size: 1.2rem;
  }
  .foot-blog .card-title h5 {
      font-size: 12px;
      margin:0px;
  }
  .foot-blog .card-title {
      font-size: 12px;
      margin:0px;
  }
  .foot-blog .card-text small {
    font-size: 0.6rem;
}
.linkFoot .nav-link{
  font-size: 13px;
}
.footSocLogo a{
  font-size: 20px;
}
.social-sticky img{
  width:40px !important;
}
.scrollDiv a{
  font-size: 11px !important;
}
.line{
  height: 40px;
}
.contDetail h3{
  font-size: 1.2rem;
}
.newsSwiper .newsSwipGrid1{
  height: 400px !important;
}
.newsSwiper .blogDetCont h4{
  font-size: 1.1rem;
}
.newsSwiper .blogDetCont{
  font-size: 14px !important;
}
.blogCard h5{
  font-size: 1rem;
}
.btn-main {
  padding: 5px 10px !important;
  font-size: 12px !important;
}
.btn-sec {
  padding: 9px 20px !important;
  width: 100%;
  font-size: 14px !important;
}
.blogSingleDesc p{
  font-size: 13px !important;
}
.featureBox img{
  width:55px !important;
}
.featureBox h6{
  font-size: 0.8rem !important;
}
.listPromise li{
  font-size: 14px !important;
}
.brndImg{
  width: 100%;
  height: 360px !important;
  object-fit: cover;
}
.mainFeat img{
  width:90px !important;
}
.mainFeat h3{
  font-size: 1.2rem !important;
}
.btn-whiteLgOut h4{
  font-size: 1rem;
}
.btn-mainLgOut h4{
  font-size: 1rem;
}
.ameniMain img{
  width: 70px;
}
.ameniMain h5{
  font-size: 1rem !important;
}
.navFixed{
  height: 70px !important;
}
.navbar-brand{
  width: 50px !important;
}
}